import './style.css';
import {Map, View} from 'ol';
import Feature from 'ol/Feature.js';
import Overlay from 'ol/Overlay.js';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import MousePosition from 'ol/control/MousePosition.js';
import {createStringXY} from 'ol/coordinate.js';
import {defaults as defaultControls} from 'ol/control.js';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point.js';

import 'bootstrap-icons/font/bootstrap-icons.css'

import {Style, Fill, Stroke, Text, Icon, Circle} from 'ol/style';

import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS.js';
import WMTSCapabilities from 'ol/format/WMTSCapabilities.js';
import {DEVICE_PIXEL_RATIO} from 'ol/has.js';

const capabilitiesUrl = 'https://basemap.at/wmts/1.0.0/WMTSCapabilities.xml';

// HiDPI support:
// * Use 'bmaphidpi' layer (pixel ratio 2) for device pixel ratio > 1
// * Use 'geolandbasemap' layer (pixel ratio 1) for device pixel ratio == 1
const hiDPI = DEVICE_PIXEL_RATIO > 1;
//const layer = hiDPI ? 'bmaphidpi' : 'geolandbasemap';
const layer = hiDPI ? 'bmapgelaende' : 'bmapgrau';

const tilePixelRatio = hiDPI ? 2 : 1;


const mousePositionControl = new MousePosition({
  coordinateFormat: createStringXY(4),
  //projection: 'EPSG:4326',
  // comment the following two lines to have the mouse position
  // be placed within the map.
  //className: 'custom-mouse-position',
  //target: document.getElementById('mouse-position'),
});


const map = new Map({
  controls: defaultControls().extend([mousePositionControl]),
  target: 'map',
  layers: [
    /*
    new TileLayer({
      source: new OSM()
    }),
    new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: './data/cyclepath.geojson',
      }),
    }),
    */
  ],
  view: new View({
    //projection: 'EPSG:4326',
    //ol.proj.fromLonLat([13.8, 46.6]),
    center: [1541270, 5879000],
    zoom: 14,
    maxZoom: 19,
    minZoom: 10,
    extent: [1541270 - 20000, 5879000 - 10000, 1541270 + 20000, 5879000 + 10000]
  })
});

const getText = function (feature, resolution) {
  
  //const maxResolution = dom.maxreso.value;
  let text = feature.get('name') + "SSSSS";
  var props = feature.getProperties();
  if (typeof props.name !== 'undefined') {
    console.log(props.name);
    return props.name;
  }
    
/*
  if (resolution > maxResolution) {
    text = '';
  } else if (type == 'hide') {
    text = '';
  } else if (type == 'shorten') {
    text = text.trunc(12);
  } else if (
    type == 'wrap' &&
    (!dom.placement || dom.placement.value != 'line')
  ) {
    text = stringDivider(text, 16, '\n');
  }
*/
  return null;
};

const createTextStyle = function (feature, resolution) {
  return new Text({
    text: getText(feature, resolution),
  });
};


function lineStyleFunction(feature, resolution) {
  return new Style({
    stroke: new Stroke({
      color: '#ffe840',
      width: 3,
    }),
    text: createTextStyle(feature, resolution),
  });
}

fetch(capabilitiesUrl)
  .then(function (response) {
    return response.text();
  })
  .then(function (text) {
    const result = new WMTSCapabilities().read(text);
    const options = optionsFromCapabilities(result, {
      layer: layer,
      matrixSet: 'google3857',
      style: 'bmapgrau',
    });
    options.tilePixelRatio = tilePixelRatio;
    options.attributions =
      'Grundkarte: <a target="_blank" href="https://basemap.at/">basemap.at</a>';
    map.addLayer(
      new TileLayer({
        source: new WMTS(options),
      })
    );
    map.addLayer(
      new VectorLayer({
        source: new VectorSource({
          format: new GeoJSON(),
          url: './data/cyclepath.geojson',
        }),
        style: lineStyleFunction,
      }),
    );

    map.addLayer(vectorLayer);

    map.addLayer(
      new VectorLayer({
        source: new VectorSource({
          format: new GeoJSON(),
          url: './data/repair.geojson',
        }),
        style: new Style({
          image: new Icon(/** @type {olx.style.IconOptions} */ ({
            color: '#ffe840',
            src: './images/symbol_repair.png'
          }))
        }),
      })
    );

    map.addLayer(
      new VectorLayer({
        source: new VectorSource({
          format: new GeoJSON(),
          url: './data/charger.geojson',
        }),
        style: new Style({
          image: new Icon(/** @type {olx.style.IconOptions} */ ({
            color: '#40adff',
            src: './images/symbol_battery.png'
          }))
        }),
      })
    );

  });

const iconFeature = new Feature({
    geometry: new Point([1541270, 5879000]),
    name: 'Überdachter Radabstellplatz<br><i class="bi bi-wrench"></i>',
});

const iconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 16],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    src: 'images/parken.png',
  }),
});

iconFeature.setStyle(iconStyle);

const vectorSource = new VectorSource({
  features: [iconFeature],
});

const vectorLayer = new VectorLayer({
  source: vectorSource,
});

const element = document.getElementById('popup');

const popup = new Overlay({
  element: element,
  positioning: 'bottom-center',
  stopEvent: false,
});
map.addOverlay(popup);

let popover;
function disposePopover() {
  if (popover) {
    popover.dispose();
    popover = undefined;
  }
}


// display popup on click
map.on('click', function (evt) {
  const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    return feature;
  });
  disposePopover();
  if (!feature) {
    return;
  }
  popup.setPosition(evt.coordinate);
  popover = new bootstrap.Popover(element, {
    container: element,
    placement: 'top',
    html: true,
    content: "<img src='./images/oeamtc.jpg' class=\"rounded-circle popup-image\"><br><b>" + feature.get('name') + "</b><br>" + "Maigasse 5<br>9500 Villach",
  });
  popover.show();
});
